
import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import Database from '../../../../Constant/database.json'
import ImageUpload1 from "../../../ImageUpload";
import ImageUpload from "./ImageUpload1";
import AudioUpload from "./AudioUpload";
import ExamQuestionModal from "./ExamQuestionModal";
import { CustomButtonUnSelect } from "../../../CustomButtomUnselect";

const QuestionBlock = [
  "question_number",
  "question",
  "question_description",
  "question_description_type",
  "question_media",
  "question_media_type",
  "option_1",
  "option_2",
  "option_3",
  "option_4",
  "answer_description",
  "correct_answer",
  "answer_media_type",
]

const questype = [
  "none",
  "text",
  "image",
  "audio",
  "choose_correct"
]
const correctAnswerArray = {
  "a": "option 1",
  "b": "option 2",
  "c": "option 3",
  "d": "option 4"
}
const optionArray = {
  "a": "option_1",
  "b": "option_2",
  "c": "option_3",
  "d": "option_4"
}

const OptionView = ({ rows, type2, value2, data_type, value, index, type, placeholder, que, onChangeFunction }) => {
  return (
    <>
      {(data_type == "text" || data_type == "choose_correct") &&
        <>
          {que ?
            <textarea rows={rows}
              className="p-0 px-2 col-12 input-common" style={{ width: "100%", margin: 0 }}
              value={value}
              onChange={(event) => {
                onChangeFunction(type, event.target.value, index)
              }}
              placeholder={placeholder}
            />
            :
            <input
              // className="p-0 px-2  input-common-4" style={{ width: "98%" }}
              className="  input-common-4"
              value={value}
              onChange={(event) => {
                onChangeFunction(type, event.target.value, index)
              }}
              placeholder={placeholder}
            />
          }
          {data_type == "choose_correct" &&
            <textarea rows={1}
              className="input-common p-0 px-2 col-12" style={{ width: "100%", margin: 0 }}
              value={value2}
              onChange={(event) => {
                onChangeFunction(type2, event.target.value, index)
              }}
              placeholder={placeholder}
            />
          }
        </>
      }
      {data_type == "image" &&
        <ImageUpload
          ImageUrl={value}
          onChange={onChangeFunction}
          type={type}
          index={index}
        />
      }
      {data_type == "audio" &&
        <AudioUpload
          ImageUrl={value}
          onChange={onChangeFunction}
          type={type}
          index={index}
        />
      }
    </>
  )
}

export default function FormComponent(props) {
  const { FetchData, db_slug, webSlug } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)

  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [Time, setTime] = React.useState(FetchData.meta.exam_time)
  const [Details, setDetails] = React.useState(FetchData.meta.exam_details)
  const [Available, setAvaliable] = React.useState(FetchData.meta.available_unavailable)
  const [FeaturedImage, setFeaturedImage] = React.useState(FetchData.meta.featured_image)
  const [ExamType, setExamType] = React.useState(FetchData.meta.exam_type)
  const [CopyTo, setCopyTo] = React.useState("")
  const [AudioSingle, setAudioSingle] = React.useState(FetchData.meta.media_non_repeat)
  const [showAD, setShowAD] = React.useState(false)

  const [QuestionsBlock, setQuestionsBlock] = React.useState(true);
  const [QuestionsData, setQuestionsData] = React.useState(
    db_slug == "demo_exam" ?
      JSON.parse(FetchData.meta.exam_question_text) :
      JSON.parse(FetchData.meta.exam_question_block_text)
  );
  const [QuestionsEditMode, setQuestionsEditMode] = React.useState(false);
  const [QuestionsEditData, setQuestionsEditData] = React.useState({});
  const [QuestionsEditKey, setQuestionsEditKey] = React.useState();
  const [AddMultipleQues, setAddMultipleQues] = React.useState(false);
  const [AddMultipleQuesText, setAddMultipleQuesText] = React.useState(false);
  const [MultipleQueText, setMultipleQueText] = React.useState("");

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")

  const EditInfo = (POSTID, slug, passSlug) => {
    setReload(true)
    console.log(QuestionsData)
    var data;
    if (slug == "daily_exam")
      data = {
        "title": Title,
        "status": "publish",
        "meta": {
          "exam_question_block_text": JSON.stringify(QuestionsData),
          "exam_time": Time,
          "batch_id": batchID,
          "batch_name": batchName,
          "exam_details": Details,
          "available_unavailable": Available,
          "featured_image": FeaturedImage,
          "media_non_repeat": AudioSingle,
          "exam_type": ExamType
        }
      }
    else if (slug == "demo_exam")
      data = {
        "title": Title,
        "status": "publish",
        "meta": {
          "exam_question_text": JSON.stringify(QuestionsData),
          "exam_time": Time,
          "exam_details": Details,
          "available_unavailable": Available,
          "featured_image": FeaturedImage,
          "media_non_repeat": AudioSingle,
          "exam_type": ExamType
        }
      }
    else
      data = {
        "title": Title,
        "status": "publish",
        "meta": {
          "exam_question_block_text": JSON.stringify(QuestionsData),
          "exam_time": Time,
          "exam_details": Details,
          "available_unavailable": Available,
          "featured_image": FeaturedImage,
          "media_non_repeat": AudioSingle,
          "exam_type": ExamType
        }
      }

    if (POSTID === "")
      AdsPOSTApi(data, slug)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Exam Added Successfully")
          console.log(res.data)
          history.push(`${passSlug}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slug, POSTID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  const [batchID, setBatchID] = React.useState(FetchData.meta.batch_id)
  const [batchName, setBatchName] = React.useState(FetchData.meta.batch_name)
  const [batchModal, setBatchModal] = React.useState(false)
  const [batchList, setBatchList] = React.useState([])
  const [expandDescription, setExpandDescription] = React.useState(false)

  useEffect(() => {
    if (db_slug == "daily_exam")
      BatchList()
  }, [])
  const BatchList = () => {
    AdsGETApiAuth(
      { per_page: 100, _fields: "id,title,meta.batch_status,meta.batch_start_date,meta.batch_end_date" },
      slugs.db_slug_batch
    )
      .then((res) => {
        setBatchList(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }


  const onChangeFunction = (key, value, index) => {
    var changeValue = {
      ...Object.values(QuestionsData)[index],
      [key]: value
    }
    var AllChange = {
      ...QuestionsData,
      [Object.keys(QuestionsData)[index]]: changeValue
    }
    setQuestionsData(AllChange)

  }
  // const onChangeFunctionClear = (key, value, index) => {
  //   // var changeValue = {
  //   //   ...Object.values(QuestionsData)[index],
  //   //   [key]: value
  //   // }
  //   var AllChange = {
  //     ...QuestionsData,
  //     [Object.keys(QuestionsData)[index]]: changeValue
  //   }
  //   setQuestionsData(AllChange)

  // }


  return (
    <div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Exam Information</center></h4>

          <table>
            <tbody>
              <tr>
                <td className="col-3">Exam Name</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Name"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>

              <tr>
                <td className="col-3">Exam Time (in minutes)</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Time} placeholder="Exam Time"
                    onChange={(event) => { setTime(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Details</td>
                <td className="col-6">
                  <textarea rows={5} className="input-common"
                    value={Details} placeholder="Exam Details"
                    onChange={(event) => { setDetails(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Featured Image</td>
                <td className="col-6">
                  <ImageUpload1
                    ImageUrl={FeaturedImage}
                    setImageUrl={setFeaturedImage}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Audio Single</td>
                <td className="col-6">
                  <div style={{ display: "inline" }}>
                    <div style={{ display: "inline-block" }}>
                      <CustomButtonUnSelect setData={setAudioSingle} Data={AudioSingle} DataValue={"Single"} />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Name</td>
                <td className="col-6">
                  <CustomButton setData={setAvaliable} Data={Available} DataValue={"Available"} />
                  <CustomButton setData={setAvaliable} Data={Available} DataValue={"Unavailable"} />
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Type</td>
                <td className="col-6">
                  <div style={{ display: "inline" }}>
                    {Database.exam_type.map((item, index) => (
                      <div style={{ display: "inline-block" }} key={index}>
                        <CustomButton setData={setExamType} Data={ExamType} DataValue={item} />
                      </div>
                    ))}
                  </div>
                </td>
              </tr>

            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo(ID, db_slug, webSlug)
                }}
              >
                Update Exam
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mb-3">
          <h4><center>Questions</center></h4>
          <div style={{ display: "inline", justifyContent: "flex-end" }}>
            <div style={{ cursor: "pointer", display: "inline-block", margin: "5px", color: QuestionsBlock ? "#000000" : "#444444", fontWeight: QuestionsBlock ? "bold" : "normal" }}
              onClick={() => {
                setQuestionsBlock(true)
                setAddMultipleQues(false)
                setAddMultipleQuesText(false)
              }}
            >
              Block
            </div>
            {/* <div style={{ cursor: "pointer", display: "inline-block", margin: "5px", color: !QuestionsBlock ? "#000000" : "#444444", fontWeight: !QuestionsBlock ? "bold" : "normal" }}
              onClick={() => {
                setAddMultipleQues(false)
                setQuestionsBlock(false)
                setAddMultipleQuesText(false)
              }}
            >
              Table
            </div> */}
            <div style={{ cursor: "pointer", display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                // setImportData([])
                setAddMultipleQues(true)
                setAddMultipleQuesText(false)
                // fileRefSingle.current.click()
              }}
            >
              Add Multiple |
            </div>
            <div style={{ cursor: "pointer", display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                // setImportData([])
                setAddMultipleQuesText(true)
                // fileRefSingle.current.click()
              }}
            >
              Add All
            </div>
          </div>
          {/* {(db_slug == slugs.db_slug_daily_exam || db_slug == slugs.db_slug_chapter_wise_exam) && */}
          <div>
            <div className="row center m-2">
              <div className=" col-3" style={{ padding: "0 10px" }}>
                <div className="sign-in-button-4"
                  onClick={() => {
                    setQuestionsEditData({
                      "question_number": `${Object.keys(QuestionsData).length + 1}`,
                      "question": "",
                      "question_description": "",
                      "question_description_type": "text",
                      "question_media": "",
                      "question_media_type": "none",
                      "option_1": "",
                      "option_2": "",
                      "option_3": "",
                      "option_4": "",
                      "answer_description": "",
                      "correct_answer": "option 1",
                      "answer_media_type": "text",
                    })
                    setQuestionsEditKey(`item-${Object.keys(QuestionsData).length}`)
                    setQuestionsEditMode(true)
                  }}
                >
                  Add Question
                </div>
              </div>
            </div>
          </div>
          {/* } */}
          {!AddMultipleQuesText ?
            <>
              {!AddMultipleQues ?
                <>
                  {QuestionsBlock ?
                    <div>
                      <div className="row m-4">
                        <div className="col-6">
                          <div className="row">
                            {Object.values(QuestionsData).map((item, index) => {
                              if (index < Object.values(QuestionsData).length / 2)
                                return (
                                  <div key={index} className="col-2 p-2 center"
                                    style={{
                                      margin: "5px",
                                      backgroundColor: item.question !== "" ? "#fad4d4" : "#fafad4",
                                      fontWeight: "bold",
                                      border: "2px solid black",
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      setQuestionsEditData(item)
                                      setQuestionsEditKey(Object.keys(QuestionsData)[index])
                                      setQuestionsEditMode(true)
                                    }}
                                  >
                                    {index + 1}
                                  </div>
                                )
                            })}
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            {Object.values(QuestionsData).map((item, index) => {
                              if (index >= Object.values(QuestionsData).length / 2)
                                return (
                                  <div key={index} className="col-2 p-2 center"
                                    style={{
                                      margin: "5px",
                                      backgroundColor: item.question !== "" ? "#fad4d4" : "#fafad4",
                                      fontWeight: "bold",
                                      border: "2px solid black",
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      setQuestionsEditData(item)
                                      setQuestionsEditKey(Object.keys(QuestionsData)[index])
                                      setQuestionsEditMode(true)
                                    }}
                                  >
                                    {index + 1}
                                    {/* {item.question_number} */}
                                  </div>
                                )
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <div>
                      <div>
                        <div className="col-12 form-view mb-3 ques-print">
                          <div style={{ overflowX: "scroll", }}>
                            {Object.values(QuestionsData).map((oneQuestionData, index) => (
                              <section key={index} style={{
                                pageBreakInside: "avoid", display: "block", cursor: "pointer", padding: "3px 0",
                              }}
                                onClick={() => {
                                  setQuestionsEditData(oneQuestionData)
                                  setQuestionsEditKey(Object.keys(QuestionsData)[index])
                                  setQuestionsEditMode(true)
                                }}
                              >
                                <div>
                                  <div>
                                    <div className="row"
                                      style={{
                                        border: "1px solid #999",
                                        borderRadius: "5px",
                                        margin: "5px",
                                        padding: 0
                                      }}>
                                      <div className="col-md-8" >
                                        <div>
                                          <div style={{ padding: "5px 0 0 0" }}>
                                            <h3 style={{ fontSize: "18px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                              {oneQuestionData.question}
                                            </h3>
                                            <h3 style={{ fontSize: "18px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                              {oneQuestionData.question_number}.
                                            </h3>
                                          </div>
                                          <div className="question-description ">
                                            {oneQuestionData.question_description_type == "text" &&
                                              <div style={{
                                                fontSize: "15px",
                                                padding: "0 0 15px"
                                              }}>
                                                <div dangerouslySetInnerHTML={{ __html: oneQuestionData.question_description.replaceAll('\n', '<br/>') }}></div>
                                              </div>
                                            }
                                            {oneQuestionData.question_description_type == "image" &&
                                              <>
                                                <div style={{ padding: "2px", textAlign: "center", }} >
                                                  <img
                                                    src={oneQuestionData.question_description}
                                                    style={{
                                                      height: "150px",
                                                      width: "392px",
                                                      objectFit: 'contain',
                                                      padding: "2px",
                                                      border: "0.5px solid #999",
                                                    }}
                                                  />
                                                </div>
                                              </>
                                            }
                                            {oneQuestionData.question_description_type == "audio" &&
                                              <div>
                                                <audio controls>
                                                  {/* <source src={`${ImageUrl}`} type="audio/ogg" /> */}
                                                  <source src={`${oneQuestionData.question_description}`} type="audio/mpeg" />
                                                  <source src={`${oneQuestionData.question_description}`} type="audio/mp3" />
                                                  <source src={`${oneQuestionData.question_description}`} type="audio/wav" />
                                                  <source src={`${oneQuestionData.question_description}`} type="audio/ogg" />
                                                  Your browser does not support the audio element.
                                                </audio>
                                                {/* <a href={oneQuestionData.question_description} target="_blank">
                                        <div style={{ cursor: "pointer" }} onClick={() => {
                                        }}>
                                          <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                            style={{ width: "20px", height: "20px" }}
                                          />
                                        </div>
                                      </a> */}
                                              </div>
                                            }
                                          </div>


                                          {oneQuestionData.question_media !== "" &&
                                            <div className="question-description col-10">
                                              {oneQuestionData.question_media_type == "text" &&
                                                <div style={{
                                                  border: "0.5px solid #999",
                                                  padding: "5px ",
                                                  justifyContent: 'center',
                                                  display: "flex"
                                                }}>
                                                  <div style={{ fontSize: "16px" }} dangerouslySetInnerHTML={{ __html: oneQuestionData.question_media.replaceAll('\n', '<br/>') }}></div>
                                                </div>
                                              }
                                              {oneQuestionData.question_media_type == "image" &&
                                                <div style={{ textAlign: "center" }}
                                                  onClick={() => {
                                                  }}
                                                >

                                                  <img
                                                    src={oneQuestionData.question_media}
                                                    style={{
                                                      height: "150px",
                                                      width: "392px",
                                                      padding: "2px",
                                                      objectFit: 'contain',
                                                      border: "0.5px solid #999"
                                                    }}
                                                  />
                                                </div>
                                              }
                                              {oneQuestionData.question_media_type == "audio" &&
                                                <div style={{ cursor: "pointer" }} onClick={() => {
                                                }}>
                                                  <audio controls>
                                                    {/* <source src={`${ImageUrl}`} type="audio/ogg" /> */}
                                                    <source src={`${oneQuestionData.question_media}`} type="audio/mpeg" />
                                                    <source src={`${oneQuestionData.question_media}`} type="audio/mp3" />
                                                    <source src={`${oneQuestionData.question_media}`} type="audio/wav" />
                                                    <source src={`${oneQuestionData.question_media}`} type="audio/ogg" />
                                                    Your browser does not support the audio element.
                                                  </audio>
                                                  {/* <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                style={{ width: "20px", height: "20px" }}
                              /> */}
                                                </div>
                                              }
                                            </div>
                                          }
                                        </div>

                                      </div>
                                      <div className={oneQuestionData.answer_media_type == "image" ? "col-md-12 ms-5" : "col-md-4"}
                                        style={{ padding: "0" }}>
                                        <div
                                          className={oneQuestionData.answer_media_type == "image" ? "row" : ""}
                                          style={{
                                          }} >
                                          <AnswerBlock index={1} DataValueText={oneQuestionData.option_1_text} DataValue={oneQuestionData.option_1} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 1"} />
                                          <AnswerBlock index={2} DataValueText={oneQuestionData.option_2_text} DataValue={oneQuestionData.option_2} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 2"} />
                                          <AnswerBlock index={3} DataValueText={oneQuestionData.option_3_text} DataValue={oneQuestionData.option_3} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 3"} />
                                          <AnswerBlock index={4} DataValueText={oneQuestionData.option_4_text} DataValue={oneQuestionData.option_4} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 4"} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {oneQuestionData.answer_description !== "" &&
                                    <div style={{
                                      border: "0.5px solid #999",
                                      padding: "10px ",
                                      justifyContent: 'center',
                                      margin: "10px",
                                      borderRadius: 10
                                    }}>
                                      <b>
                                        Answer Description
                                      </b>
                                      <div onClick={() => { setShowAD(!showAD) }}>
                                        {showAD ? "Show" : "Hide"}
                                      </div>
                                      {!showAD &&
                                        <div style={{ fontSize: "16px" }} dangerouslySetInnerHTML={{ __html: oneQuestionData.answer_description.replaceAll('\n', '<br/>') }}></div>
                                      }
                                    </div>
                                  }
                                </div>
                              </section>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </>
                :
                <>
                  <div>
                    {Object.values(QuestionsData).map((oneQuestionData, index) => (
                      <section key={index} style={{
                        pageBreakInside: "avoid", display: "block", cursor: "pointer", padding: "3px 0",
                      }}
                        className="outer-container"
                      >
                        <QuestionView
                          oneQuestionData={oneQuestionData}
                          onChangeFunction={onChangeFunction}
                          index={index}
                          QuestionsData={QuestionsData}
                          setQuestionsData={setQuestionsData}
                          EditInfo={EditInfo}
                          ID={ID} db_slug={db_slug} webSlug={webSlug}
                          setQuestionsBlock={setQuestionsBlock} setAddMultipleQues={setAddMultipleQues}
                        />

                      </section>
                    ))}
                    {console.log(QuestionsData)}
                  </div>

                </>
              }
            </>
            :
            <>
              <div>
                <textarea rows={20}
                  className="input-common-41"
                  value={MultipleQueText} placeholder="Question"
                  onChange={(event) => {
                    setMultipleQueText(event.target.value)
                  }}
                />
                <div className="sign-in-button-4"
                  onClick={() => {
                    var replaceTab = MultipleQueText.replaceAll("\t", "")
                    var allarray = replaceTab.split("\n").filter(a => a !== "")
                    // allarray = allarray
                    // setMultipleQueText(allarray)
                    var question_number = 1
                    var objectValuesAll = {}
                    var totalQuestion = 0;
                    var newarray = []
                    for (let k = 0; k < allarray.length; k++) {
                      // console.log(Number(`${allarray[k][0]}${allarray[k][1]}`), "a")
                      if (Number(`${allarray[k][0]}${allarray[k][1]}`) == Number(totalQuestion) + 1) {
                        totalQuestion++;
                      }
                    }
                    console.log(totalQuestion, allarray.length)
                    let t = 0;

                    for (let i = 0; i <= totalQuestion; i++) {
                      var objectValues = {}
                      var Nownumber = question_number
                      for (let j = 0; j < 20; j++) {
                        // let j = 0;
                        // while (true) {
                        var check = allarray[t]
                        // var Thisnumber = `${allarray[t][0]}${allarray[t][1]}`
                        // console.log(i, t, allarray[t])
                        var Thisnumber;
                        if (allarray[t] !== undefined) {
                          Thisnumber = `${allarray[t][0]}${allarray[t][1]}`
                          t++;
                          if (question_number == Number(Thisnumber)) {
                            question_number++;
                            if (Nownumber + 2 == question_number) {
                              question_number--;
                              // console.log("12")
                              t--;
                              break;
                            }
                            objectValues = {
                              ...objectValues,
                              "question_number": Number(Thisnumber),
                              [`question`]: check,
                              "question_description": "",
                              "question_description_type": "text",
                              "question_media": "",
                              "question_media_type": "text",
                              "answer_media_type": "text",
                            }
                          }
                          else {
                            // if (question_number - 1 == Nownumber)
                            if (
                              Thisnumber[0] == "A" ||
                              Thisnumber[0] == "B" ||
                              Thisnumber[0] == "C" ||
                              Thisnumber[0] == "D"
                            ) {
                              console.log(Thisnumber, 754, check)
                              if (check.includes("*"))
                                objectValues = {
                                  ...objectValues,
                                  [`${optionArray[Thisnumber[0].toLowerCase()]}`]:
                                    check
                                      .replace("*", "")
                                      .replace("A.", "")
                                      .replace("B.", "")
                                      .replace("C.", "")
                                      .replace("D.", "")
                                  ,
                                  "correct_answer": `${correctAnswerArray[Thisnumber[0].toLowerCase()]}`,
                                }
                              else
                                objectValues = {
                                  ...objectValues,
                                  [`${optionArray[Thisnumber[0].toLowerCase()]}`]:
                                    check
                                      .replace("A.", "")
                                      .replace("B.", "")
                                      .replace("C.", "")
                                      .replace("D.", ""),
                                }
                            }
                            if (Thisnumber[0] == "Q") {
                              console.log(2593)
                              objectValues = {
                                ...objectValues,
                                "question_description": `${check.replace("Q.", "")}`,
                              }
                            }
                            if (Thisnumber[0] == "M") {
                              objectValues = {
                                ...objectValues,
                                "question_media": `${check.replace("M.", "")}`,
                              }
                            }
                          }
                        }
                      }
                      objectValuesAll = {
                        ...objectValuesAll,
                        [`item-${i}`]: objectValues
                      }
                      // j++;
                    }
                    console.log(objectValuesAll)
                    setQuestionsData(objectValuesAll)
                  }
                  }
                >
                  Add to Question
                </div>
              </div>

            </>
          }
        </div>
      </div>

      {/* <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Make a copy to:</center></h4>
          <CustomButton setData={setCopyTo} Data={CopyTo} DataValue={"Batch Wise Exam"} />
          {db_slug == "daily_exam" &&
            <>
              {Object.keys(QuestionsData).length == 40 &&
                <>
                  <CustomButton setData={setCopyTo} Data={CopyTo} DataValue={"All Exam"} />
                  <CustomButton setData={setCopyTo} Data={CopyTo} DataValue={"Demo Exam"} />
                </>
              }
            </>
          }
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  if (CopyTo == "Batch Wise Exam")
                    EditInfo("", slugs.db_slug_daily_exam, slugs.daily_exam_details)
                  else if (CopyTo == "All Exam")
                    EditInfo("", slugs.db_slug_paid_exam, slugs.exam_details)
                    else
                    EditInfo("", slugs.db_slug_demo_exam, slugs.demo_exam_details)
                }}
              >
                Make a copy
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {
        QuestionsEditMode &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setQuestionsEditMode(false)
                    setQuestionsEditData([])
                    setQuestionsEditKey("")
                  }}
                >X</div>
                <ExamQuestionModal
                  oneQuestionData={QuestionsEditData}
                  keyValue={QuestionsEditKey}
                  AllQuestionData={QuestionsData}
                  setAllQuestionData={setQuestionsData}
                  PostExam={EditInfo}
                  setEditQuestion={setQuestionsEditMode}
                />
              </div>
            </div>
          </div>
        </section>
      }


      {
        reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div >
  )

}

const AnswerDescriptionView = ({ oneQuestionData, onChangeFunction, index }) => {
  const [expandDescription, setExpandDescription] = React.useState(false)
  return (
    <div>
      <textarea rows={expandDescription ? 11 : 2}
        className="p-0 px-2 col-12 input-common" style={{ width: "100%" }}
        value={oneQuestionData.answer_description}
        onChange={(event) => {
          onChangeFunction("answer_description", event.target.value, index)
        }}
        placeholder="Answer Description"
      />
      <div className="" style={{ padding: "0 10px" }}>
        <div
          onClick={() => {
            setExpandDescription(!expandDescription)
          }}
        >
          <span style={{ color: "#f00", fontWeight: "bold" }}>
            Expand Description
          </span>
        </div>
      </div>
    </div>
  )
}


const AnswerBlock = ({ index, DataValueText, DataType, DataValue, correct_answer, option }) => {
  return (
    <div
      className={DataType == "image" ? "col-6" : "col-12"}
      style={{
        borderLeft: option == correct_answer ? "2px solid green" : 0,
        // borderLeft: 0,
        display: 'flex',
        padding: "5px 10px",
      }}
    >
      <div style={{ marginRight: "5px", cursor: "pointer" }}>
        <div style={{
          border: option == correct_answer ? "2px solid green" : "2px solid black",
          // border: "2px solid black",
          backgroundColor: "#fff",
          color: "#000",
          borderRadius: "14px",
          width: "20px",
          height: "20px", display: "flex", justifyContent: "center", alignContent: "center",
          fontSize: "12px", fontWeight: "bold",
          color: option == correct_answer ? "green" : "black",
        }}>
          &nbsp;{index}&nbsp;
        </div>
      </div>
      <div style={{
        fontSize: "16px",
        fontWeight: option == correct_answer ? "800" : "400",
      }} className="passive-option2">
        {(DataType == "text" || DataType == "choose_correct") &&
          // {DataType == "text" &&
          <div dangerouslySetInnerHTML={{ __html: DataValue.replace(DataValueText, `<u>${DataValueText}</u>`) }}></div>
          // `${DataValue}`
        }
        {DataType == "image" &&
          <>
            <img
              src={DataValue}
              style={{
                width: "120px",
                border: "0.5px solid #000"
              }}
            />
          </>
        }
        {DataType == "audio" &&
          <div>
            <audio controls>
              {/* <source src={`${ImageUrl}`} type="audio/ogg" /> */}
              <source src={`${DataValue}`} type="audio/mpeg" />
              <source src={`${DataValue}`} type="audio/mp3" />
              <source src={`${DataValue}`} type="audio/wav" />
              <source src={`${DataValue}`} type="audio/ogg" />
              Your browser does not support the audio element.
            </audio>
            <a href={DataValue} target="_blank">
              <div style={{ cursor: "pointer" }} onClick={() => { }}>
                <div>
                </div>
              </div>
            </a>
          </div>
        }
      </div>
    </div>
  )
}


const QuestionView = ({
  EditInfo, oneQuestionData,
  onChangeFunction, index,
  QuestionsData, setQuestionsData, setQuestionsBlock, setAddMultipleQues,
  ID, db_slug, webSlug
}) => {

  const [showAD, setShowAD] = React.useState(false)
  const [tableView, setTableView] = React.useState(true)
  return (
    <div>
      {tableView ?
        <div className="row"
          style={{
            border: "1px solid #999",
            borderRadius: "5px",
            margin: "5px",
            padding: "10px",
            // backgroundColor: "#fff",
          }}>
          <div className="col-sm-7"
            style={{ padding: "5px 0 0 0" }}
          >
            <h3 style={{ fontSize: "18px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
              {/* {oneQuestionData.question_number}. */}
              {index + 1}.
            </h3>
            <div>
              <span style={{ color: "#f00", fontWeight: "bold" }}>
                Question.
              </span>
              <input
                className="p-0 px-2 col-12 input-common" style={{ width: "100%" }}
                value={oneQuestionData.question}
                onChange={(event) => {
                  onChangeFunction("question", event.target.value, index)
                }}
                placeholder="Question"
              />
            </div>
            <div>
              <div style={{ fontSize: "16px", padding: "3px", backgroundColor: "#cbf7de" }}>
                <span style={{ color: "#f00", fontWeight: "bold" }}>
                  Desc:
                </span>
                {questype.map((item1, index1) => (
                  <span key={index1}
                    style={{ fontWeight: oneQuestionData.question_description_type == item1 ? "bold" : "400", backgroundColor: oneQuestionData.question_description_type == item1 ? "#fff" : "#ffffff00" }}
                    onClick={() => { onChangeFunction("question_description_type", item1, index) }}>{item1} </span>
                ))}
              </div>
              {oneQuestionData.question_description_type !== "none" &&
                <>
                  <OptionView
                    data_type={oneQuestionData.question_description_type}
                    value={oneQuestionData.question_description}
                    index={index}
                    type={"question_description"}
                    placeholder={"Question Description"}
                    que={true}
                    rows={1}
                    onChangeFunction={onChangeFunction}
                  />
                </>
              }
            </div>
            <div>
              <div style={{ fontSize: "16px", padding: "3px", backgroundColor: "#b7b4fa" }}>
                <span style={{ color: "#f00", fontWeight: "bold" }}>
                  Media:
                </span>

                {questype.map((item1, index1) => (
                  <span key={index1}
                    style={{ fontWeight: oneQuestionData.question_media_type == item1 ? "bold" : "400", backgroundColor: oneQuestionData.question_media_type == item1 ? "#fff" : "#ffffff00" }}
                    // style={{ fontWeight: oneQuestionData.question_media_type == item1 ? "bold" : "400" }}
                    onClick={() => { onChangeFunction("question_media_type", item1, index) }}>{item1} </span>
                ))}
              </div>
              <OptionView
                data_type={oneQuestionData.question_media_type}
                value={oneQuestionData.question_media}
                index={index}
                type={"question_media"}
                placeholder={"Question Media"}
                rows={3}
                que={true}
                onChangeFunction={onChangeFunction}
              />
            </div>

            {/* <h3 style={{ fontSize: "18px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                {oneQuestionData.answer_description}.
              </h3> */}
            <div>
              Answer Description.
              <AnswerDescriptionView
                oneQuestionData={oneQuestionData}
                onChangeFunction={onChangeFunction}
                index={index}
              />
              {/* <textarea rows={expandDescription ? 11 : 2}
                  className="p-0 px-2 col-12 input-common" style={{ width: "100%" }}
                  value={oneQuestionData.answer_description}
                  onChange={(event) => {
                    onChangeFunction("answer_description", event.target.value, index)
                  }}
                  placeholder="Answer Description"
                /> */}
            </div>
            <div className="row center m-2">

              <div className=" col-3" style={{ padding: "0 10px" }}>
                <div
                  onClick={() => {
                    setTableView(false)
                  }}
                >
                  <span style={{ color: "#0c068c", fontWeight: "bold" }}>
                    Table
                  </span>
                </div>
              </div>
              <div className=" col-3" style={{ padding: "0 10px" }}>
                <div
                  onClick={() => {
                    EditInfo(ID, db_slug, webSlug)
                  }}
                >
                  <span style={{ color: "#0c068c", fontWeight: "bold" }}>
                    Update
                  </span>
                </div>
              </div>
              <div className=" col-3" style={{ padding: "0 10px" }}>
                <div
                  onClick={() => {
                    const clearque = {
                      ...oneQuestionData,
                      "question_description": "",
                      "question_media": "",
                      "question_text": "",
                    }
                    var AllChange = {
                      ...QuestionsData,
                      [Object.keys(QuestionsData)[index]]: clearque
                    }
                    setQuestionsData(AllChange)
                  }}
                >
                  <span style={{ color: "#126307", fontWeight: "bold" }}>
                    Clear Que
                  </span>
                </div>
              </div>

              <div className=" col-3" style={{ padding: "3px 10px" }}>
                <div
                  onClick={() => {
                    const clearque = {
                      ...oneQuestionData,
                      "option_1": "",
                      "option_1_text": "",
                      "option_2": "",
                      "option_2_text": "",
                      "option_3": "",
                      "option_3_text": "",
                      "option_4": "",
                      "option_4_text": "",
                    }
                    var AllChange = {
                      ...QuestionsData,
                      [Object.keys(QuestionsData)[index]]: clearque
                    }
                    setQuestionsData(AllChange)
                    // onChangeFunctionClear(question_media_type, "", index)
                  }}
                >
                  <span style={{ color: "#126307", fontWeight: "bold" }}>
                    Clear Ans
                  </span>
                </div>
              </div>
              <div className="col-3" style={{ padding: "3px 10px",  backgroundColor: "#f0faf3" }}>
                <div
                  onClick={() => {
                    navigator.clipboard.writeText(JSON.stringify(oneQuestionData))
                  }}
                >
                  <span style={{ color: "#000", fontWeight: "bold" }}>
                    Copy
                  </span>
                </div>
              </div>
              <div className="col-3" style={{ padding: "3px 10px", backgroundColor: "#f0f0f0" }}>
                <div
                  onClick={async () => {
                    var pasteValue = await navigator.clipboard.readText()
                    var parseValue = JSON.parse(pasteValue)
                    var AllChange = {
                      ...QuestionsData,
                      [Object.keys(QuestionsData)[index]]: parseValue
                    }
                    setQuestionsData(AllChange)
                  }}
                >
                  <span style={{ color: "#000", fontWeight: "bold" }}>
                    Paste
                  </span>
                </div>
              </div>
              <div className="col-3" style={{ padding: "3px 10px", backgroundColor: "#b7b4fa" }}>
                <div
                  onClick={async () => {
                    var newValue = {
                      "question_number": `${Object.keys(QuestionsData).length + 1}`,
                      "question": "",
                      "question_description": "",
                      "question_description_type": "text",
                      "question_media": "",
                      "question_media_type": "none",
                      "option_1": "",
                      "option_2": "",
                      "option_3": "",
                      "option_4": "",
                      "answer_description": "",
                      "correct_answer": "option 1",
                      "answer_media_type": "text",
                    }
                    const newKey = `item-${Object.keys(QuestionsData).length + 1}`
                    const newObject = {};
                    Object.keys(QuestionsData).forEach((key) => {
                      newObject[key] = QuestionsData[key];
                      if (key === Object.keys(QuestionsData)[index]) {
                        newObject[newKey] = newValue;
                      }
                    });
                    setQuestionsData(newObject)
                  }}
                >
                  <span style={{ color: "#000", fontWeight: "bold" }}>
                    Add
                  </span>
                </div>
              </div>
              <div className="col-3" style={{ padding: "3px 10px", backgroundColor: "#b70000" }}>
                <div
                  onClick={async () => {
                    navigator.clipboard.writeText(JSON.stringify(oneQuestionData))
                    const newObject = QuestionsData;
                    // Object.keys(QuestionsData).forEach((key) => {
                    //   newObject[key] = QuestionsData[key];
                    //   if (key === Object.keys(QuestionsData)[index]) {
                    //     newObject[newKey] = newValue;
                    //   }
                    // });
                    delete newObject[Object.keys(QuestionsData)[index]]
                    console.log(newObject, Object.keys(QuestionsData)[index])
                    setQuestionsData(newObject)
                    setQuestionsBlock(true)
                    setAddMultipleQues(false)
                  }}
                >
                  <span style={{ color: "#fff", fontWeight: "bold", }}>
                    Remove
                  </span>
                </div>
              </div>
            </div>

          </div>
          <div className="col-sm-5 answer-style">
            <div style={{ backgroundColor: "#f7d7d9" }}>
              {questype.map((item1, index1) => (
                <span key={item1}>
                  {item1 !== "none" &&
                    <span
                      style={{ fontWeight: oneQuestionData.answer_media_type == item1 ? "bold" : "400", backgroundColor: oneQuestionData.answer_media_type == item1 ? "#fff" : "#ffffff00" }}
                      // style={{ fontWeight: oneQuestionData.answer_media_type == item1 ? "bold" : "400" }}
                      onClick={() => { onChangeFunction("answer_media_type", item1, index) }}>{item1} </span>
                  }
                </span>
              ))}
            </div>
            <br />
            <div>
              <span className={oneQuestionData.correct_answer == "option 1" ? "option-correct" : "option-wrong"}
                onClick={() => {
                  onChangeFunction("correct_answer", "option 1", index)
                }}
              >
                &nbsp;1&nbsp;
              </span>

              <OptionView
                data_type={oneQuestionData.answer_media_type}
                value2={oneQuestionData.option_1_text}
                value={oneQuestionData.option_1}
                index={index}
                type2={"option_1_text"}
                type={"option_1"}
                placeholder={"Option 1"}
                rows={2}
                que={true}
                onChangeFunction={onChangeFunction}
              />
            </div>
            <div>
              <div>

                <span className={oneQuestionData.correct_answer == "option 2" ? "option-correct" : "option-wrong"}
                  onClick={() => {
                    onChangeFunction("correct_answer", "option 2", index)
                  }}
                >
                  &nbsp;2&nbsp;
                </span>
              </div>
              <div >
                <OptionView
                  data_type={oneQuestionData.answer_media_type}
                  type2={"option_2_text"}
                  value2={oneQuestionData.option_2_text}
                  value={oneQuestionData.option_2}
                  index={index}
                  type={"option_2"}
                  placeholder={"Option 2"}
                  rows={2}
                  que={true}
                  onChangeFunction={onChangeFunction}
                />
              </div>
            </div>
            <div>
              <div>
                <span className={oneQuestionData.correct_answer == "option 3" ? "option-correct" : "option-wrong"}
                  onClick={() => {
                    onChangeFunction("correct_answer", "option 3", index)
                  }}
                >
                  &nbsp;3&nbsp;
                </span>
              </div>
              <div >
                <OptionView
                  data_type={oneQuestionData.answer_media_type}
                  value2={oneQuestionData.option_3_text}
                  value={oneQuestionData.option_3}
                  index={index}
                  type={"option_3"}
                  type2={"option_3_text"}
                  placeholder={"Option 3"}
                  rows={2}
                  que={true}
                  onChangeFunction={onChangeFunction}
                />
              </div>
            </div>
            <div  >
              <div  >

                <span className={oneQuestionData.correct_answer == "option 4" ? "option-correct" : "option-wrong"}
                  onClick={() => {
                    onChangeFunction("correct_answer", "option 4", index)
                  }}
                >
                  &nbsp;4&nbsp;
                </span>
              </div>
              <div>
                <OptionView
                  data_type={oneQuestionData.answer_media_type}
                  value2={oneQuestionData.option_4_text}
                  value={oneQuestionData.option_4}
                  index={index}
                  type={"option_4"}
                  type2={"option_4_text"}
                  placeholder={"Option 4"}
                  rows={2}
                  que={true}
                  onChangeFunction={onChangeFunction}
                />
              </div>
            </div>
          </div>
        </div>
        :
        <div>
          <div>
            <div>
              <div className="row"
                style={{
                  border: "1px solid #999",
                  borderRadius: "5px",
                  margin: "5px",
                  padding: 0
                }}>
                <div className="col-md-8" >
                  <div>
                    <div style={{ padding: "5px 0 0 0" }}>
                      <h3 style={{ fontSize: "18px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                        {oneQuestionData.question}
                      </h3>
                      <h3 style={{ fontSize: "18px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                        {oneQuestionData.question_number}.
                      </h3>
                    </div>
                    <div className="question-description ">
                      {oneQuestionData.question_description_type == "text" &&
                        <div style={{
                          fontSize: "15px",
                          padding: "0 0 15px"
                        }}>
                          <div dangerouslySetInnerHTML={{ __html: oneQuestionData.question_description.replaceAll('\n', '<br/>') }}></div>
                        </div>
                      }
                      {oneQuestionData.question_description_type == "image" &&
                        <>
                          <div style={{ padding: "2px", textAlign: "center", }} >
                            <img
                              src={oneQuestionData.question_description}
                              style={{
                                height: "150px",
                                width: "392px",
                                objectFit: 'contain',
                                padding: "2px",
                                border: "0.5px solid #999",
                              }}
                            />
                          </div>
                        </>
                      }
                      {oneQuestionData.question_description_type == "audio" &&
                        <div>
                          <audio controls>
                            {/* <source src={`${ImageUrl}`} type="audio/ogg" /> */}
                            <source src={`${oneQuestionData.question_description}`} type="audio/mpeg" />
                            <source src={`${oneQuestionData.question_description}`} type="audio/mp3" />
                            <source src={`${oneQuestionData.question_description}`} type="audio/wav" />
                            <source src={`${oneQuestionData.question_description}`} type="audio/ogg" />
                            Your browser does not support the audio element.
                          </audio>
                          {/* <a href={oneQuestionData.question_description} target="_blank">
                                        <div style={{ cursor: "pointer" }} onClick={() => {
                                        }}>
                                          <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                            style={{ width: "20px", height: "20px" }}
                                          />
                                        </div>
                                      </a> */}
                        </div>
                      }
                    </div>


                    {oneQuestionData.question_media !== "" &&
                      <div className="question-description col-10">
                        {oneQuestionData.question_media_type == "text" &&
                          <div style={{
                            border: "0.5px solid #999",
                            padding: "5px ",
                            justifyContent: 'center',
                            display: "flex"
                          }}>
                            <div style={{ fontSize: "16px" }} dangerouslySetInnerHTML={{ __html: oneQuestionData.question_media.replaceAll('\n', '<br/>') }}></div>
                          </div>
                        }
                        {oneQuestionData.question_media_type == "image" &&
                          <div style={{ textAlign: "center" }}
                            onClick={() => {
                            }}
                          >

                            <img
                              src={oneQuestionData.question_media}
                              style={{
                                height: "150px",
                                width: "392px",
                                padding: "2px",
                                objectFit: 'contain',
                                border: "0.5px solid #999"
                              }}
                            />
                          </div>
                        }
                        {oneQuestionData.question_media_type == "audio" &&
                          <div style={{ cursor: "pointer" }} onClick={() => {
                          }}>
                            <audio controls>
                              {/* <source src={`${ImageUrl}`} type="audio/ogg" /> */}
                              <source src={`${oneQuestionData.question_media}`} type="audio/mpeg" />
                              <source src={`${oneQuestionData.question_media}`} type="audio/mp3" />
                              <source src={`${oneQuestionData.question_media}`} type="audio/wav" />
                              <source src={`${oneQuestionData.question_media}`} type="audio/ogg" />
                              Your browser does not support the audio element.
                            </audio>
                            {/* <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                style={{ width: "20px", height: "20px" }}
                              /> */}
                          </div>
                        }
                      </div>
                    }
                  </div>

                </div>
                <div className={oneQuestionData.answer_media_type == "image" ? "col-md-12 ms-5" : "col-md-4"}
                  style={{ padding: "0" }}>
                  <div
                    className={oneQuestionData.answer_media_type == "image" ? "row" : ""}
                    style={{
                    }} >
                    <AnswerBlock index={1} DataValue={oneQuestionData.option_1} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 1"} />
                    <AnswerBlock index={2} DataValue={oneQuestionData.option_2} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 2"} />
                    <AnswerBlock index={3} DataValue={oneQuestionData.option_3} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 3"} />
                    <AnswerBlock index={4} DataValue={oneQuestionData.option_4} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 4"} />
                  </div>
                </div>
              </div>
            </div>
            {/* {oneQuestionData.answer_description !== "" &&
              <div style={{
                border: "0.5px solid #999",
                padding: "10px ",
                justifyContent: 'center',
                margin: "10px",
                borderRadius: 10
              }}>
                <b>
                  Answer Description
                </b>
                <div onClick={() => { setShowAD(!showAD) }}>
                  {showAD ? "Show" : "Hide"}
                </div>
                {!showAD &&
                  <div style={{ fontSize: "16px" }} dangerouslySetInnerHTML={{ __html: oneQuestionData.answer_description.replaceAll('\n', '<br/>') }}></div>
                }
              </div>
            } */}
          </div>
          <div className="row center m-2">

            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div
                onClick={() => {
                  setTableView(true)
                }}
              >
                <span style={{ color: "#0c068c", fontWeight: "bold" }}>
                  Edit
                </span>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}